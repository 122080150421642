import React, { useState } from 'react';

const Services = () => {
  const [isMoreView, setIsMoreView] = useState(false);

  const togglePlans = () => {
    setIsMoreView(!isMoreView);
  };

  // Define all plans
  const plans = [
    { name: 'LITE', price: 'R29.00/month', features: ['2GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
    { name: 'BASIC', price: 'R79.00/month', features: ['5GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
    { name: 'STANDARD', price: 'R269.00/month', features: ['35GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
    { name: 'PRO', price: 'R269.00/month', features: ['35GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
    { name: 'ADVANCED', price: 'R269.00/month', features: ['35GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
    { name: 'PREMIUM', price: 'R49.00/month', features: ['3GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
    { name: 'ULTIMATE', price: 'R119.00/month', features: ['15GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
    { name: 'MAX', price: 'R179.00/month', features: ['25GB Disk Space', 'Un Metered Bandwidth', '1 Hosted Domain', 'Unlimited E-mail Accounts', 'POP/IMAP/SMTP', 'Daily Backups'] },
  ];

  // Get first, middle, and last plans for the "Less" view
  const firstPlan = plans[0];
  const middlePlan = plans[Math.floor(plans.length / 2)];
  const lastPlan = plans[plans.length - 1];

  return (
    <section className="services" id="services">
      <h2>Our Hosting Services</h2>

      <div className="service-item">
        <h3>Website Hosting</h3>
        <p>Reliable and fast website hosting to get your site online with minimal downtime.</p>

        <div className="hosting-plans">
          {/* Show "Less" view (1st, middle, and last plans) */}
          {!isMoreView && (
            <>
              <div className="hosting-plan">
                <h4>{firstPlan.name}</h4>
                <p className="price">{firstPlan.price}</p>
                <ul className="features">
                  {firstPlan.features.map((feature, index) => <li key={index}>{feature}</li>)}
                </ul>
              </div>

              <div className="hosting-plan">
                <h4>{middlePlan.name}</h4>
                <p className="price">{middlePlan.price}</p>
                <ul className="features">
                  {middlePlan.features.map((feature, index) => <li key={index}>{feature}</li>)}
                </ul>
              </div>

              <div className="hosting-plan">
                <h4>{lastPlan.name}</h4>
                <p className="price">{lastPlan.price}</p>
                <ul className="features">
                  {lastPlan.features.map((feature, index) => <li key={index}>{feature}</li>)}
                </ul>
              </div>
            </>
          )}

          {/* Show "More" view (All plans) */}
          {isMoreView && (
            <>
              {plans.map((plan, index) => (
                <div key={index} className="hosting-plan">
                  <h4>{plan.name}</h4>
                  <p className="price">{plan.price}</p>
                  <ul className="features">
                    {plan.features.map((feature, index) => <li key={index}>{feature}</li>)}
                  </ul>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="show-more-btn">
          <button onClick={togglePlans} className="btn btn-secondary">
            {isMoreView ? 'Show Less' : 'Show More'}
          </button>
        </div>

        <div className="price-card">
          <a href="https://myaccount.cktech.co.za" className="btn btn-primary">Get Started</a>
        </div>
      </div>

      <div className="service-item">
        <h3>Domain Registration</h3>
        <p>Find the perfect domain for your business and register it with ease.</p>
        <div className="domain-pricing">
          <div className="pricing-option">
            <h4>.co.za</h4>
            <p>R99/year</p>
          </div>
          <div className="pricing-option">
            <h4>.com</h4>
            <p>R219/year</p>
          </div>
          <div className="pricing-option">
            <h4>.mobi</h4>
            <p>R460/year</p>
          </div>
        </div>
        <p className="contact-us">Contact us for more domain options and pricing.</p>
        <div className="price-card">
          <a href="https://myaccount.cktech.co.za" className="btn btn-primary">Get Started</a>
        </div>
      </div>

      <div className="service-item">
        <h3>Email Hosting</h3>
        <p>Professional email hosting services to keep your business communication secure.</p>
        <div className="price-card">
          <h4>Starting from</h4>
          <p>$5/month</p>
          <a href="https://myaccount.cktech.co.za" className="btn btn-primary">Get Started</a>
        </div>
      </div>
    </section>
  );
};

export default Services;
 