import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <h1>HostWorld</h1>
      </div>
      <nav>
        <ul>
          <li><a href="#services">Services</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
      <div className="auth-buttons">
        <a href="https://myaccount.cktech.co.za" className="btn">Login</a>
        <a href="https://myaccount.cktech.co.za/register.php" className="btn btn-register">Register</a>
      </div>
    </header>
  );
};

export default Header;
